<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <base-card>
          <div slot="heading" class="title font-weight-light">
            {{ $t('users.user_management') }}
          </div>
          <v-flex xs12>
            <user-list
              :users="users.data"
              :meta="users.meta"
              :per-page="params.perPage"
              @selected="onSelected"
              @fetch-with-condition="fetchWithCondition"
              @prev-page="fetchPrevPage"
              @next-page="fetchNextPage"
            />
          </v-flex>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UserList from '../components/UserList.vue'
import { DEFAULT_PER_PAGE } from '@/constants'
import BaseCard from '../components/common/BaseCard.vue'

export default {
  components: {
    UserList,
    BaseCard,
  },

  data() {
    return {
      params: {
        perPage: DEFAULT_PER_PAGE,
      },
      lastNext: 0,
    }
  },

  mounted() {
    this.fetchUsers({ params: this.params })
  },

  computed: {
    ...mapState({
      users: (state) => state.user.users,
    }),
  },

  methods: {
    ...mapActions(['fetchUsers', 'updateUser']),

    async onSelected(userId) {
      this.$router.push({ name: 'userDetail', params: { userId } })
    },

    fetchWithCondition(key, param) {
      this.params = { ...this.params, [key]: param }
      this.fetchUsers({ params: this.params })
    },

    fetchNextPage() {
      const { next } = this.users.meta
      this.lastNext = next
      this.params = { ...this.params, cursor: next }
      this.fetchUsers({ params: this.params })
    },

    fetchPrevPage() {
      const diff = this.lastNext - this.params.perPage
      this.lastNext = diff
      this.params = { ...this.params, cursor: diff }
      this.fetchUsers({ params: this.params })
    },
  },
}
</script>
