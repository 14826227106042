<template>
  <v-card>
    <v-toolbar color="teal" dark>
      <v-row justify="space-between">
        <v-col cols="4" align-self="center">
          <v-toolbar-title>{{ $t('users.index') }}</v-toolbar-title>
        </v-col>

        <v-col cols="8">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="userName"
                append-icon="search"
                :label="$t('users.name')"
                single-line
                hide-details
                @keydown.enter="searchByName"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="userEmail"
                append-icon="search"
                :label="$t('users.email')"
                single-line
                hide-details
                @keydown.enter="searchByEmail"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="5%">ID</th>
            <th width="5%" />
            <th class="text-left" width="35%">
              {{ $t('users.name') }}
            </th>
            <th class="text-left" width="35%">
              {{ $t('users.email') }}
            </th>
            <th class="text-left" width="20%">
              {{ $t('users.adminAuth') }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(user, index) in users" :key="index" @click="onSelected(user)">
            <td>{{ user.id }}</td>
            <td>
              <v-avatar size="36">
                <img src="/abeja.jpg" />
              </v-avatar>
            </td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>
              <v-icon color="pink">
                {{ user.is_admin ? 'star' : 'star_border' }}
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <pagination
      :current-per-page="perPage"
      :is-first-page="hasPreviousPage"
      :is-last-page="isLastPage"
      @prev-page="$emit('prev-page')"
      @next-page="$emit('next-page')"
      @change-per-page="searchByPerPage"
    />
  </v-card>
</template>

<script>
import Pagination from './ui/Pagination.vue'
import { DEFAULT_PER_PAGE } from '@/constants'

export default {
  components: {
    Pagination,
  },

  props: {
    users: { type: Array, default: () => [] },
    meta: { type: Object, default: () => {} },
    perPage: { type: Number, default: DEFAULT_PER_PAGE },
  },

  data() {
    return {
      userName: null,
      userEmail: null,
    }
  },

  computed: {
    hasPreviousPage() {
      if (this.isLastPage) {
        return false
      }
      // TODO: check the page is first page in proper way
      return this.meta && this.meta.next - this.perPage === 1
    },

    isLastPage() {
      return this.meta && !this.meta.next
    },
  },

  methods: {
    onSelected(user) {
      this.$emit('selected', user.id)
    },

    handleChangePerPage(perPage) {
      this.$emit('change-per-page', perPage)
    },

    searchByName() {
      this.$emit('fetch-with-condition', 'name', this.userName)
    },

    searchByEmail() {
      this.$emit('fetch-with-condition', 'email', this.userEmail)
    },

    searchByPerPage(perPage) {
      this.$emit('fetch-with-condition', 'perPage', perPage)
    },
  },
}
</script>
